/**
  * $name 10. Accent color
  * $type color
  */
/**
* $name 10. Font family
* $type text
*/
/**
* $name 20. Text color
* $type color
*/
/**
* $name 20. Label color
* $type color
*/
/**
* $name 40. Icon color
* $type color
*/
/**
* $name 50. Arrow icon color
* $type color
*/
/**
* $name 30. Background color
* $type color
*/
/**
* $name 40. Border color
* $type color
*/
/**
* $name 50. Success color
* $type color
*/
/**
* $name 60. Warning color
* $type color
*/
/**
* $name 70. Danger color
* $type color
*/
/**
* $name 80. Hover state text color
* $type color
*/
/**
* $name 90. Hover state background color
* $type color
*/
/**
* $name 100. Focused state text color
* $type color
*/
/**
* $name 110. Focused state background color
* $type color
*/
/**
* $name 30. Link color
* $type color
*/
:root {
  --dx-component-color-bg: #fff;
  --dx-color-main-bg: #fff;
  --dx-color-primary: #ff5722;
  --dx-color-danger: #f44336;
  --dx-color-success: #8bc34a;
  --dx-color-warning: #ffc107;
  --dx-color-border: #e0e0e0;
  --dx-color-text: rgba(0, 0, 0, 0.87);
  --dx-color-icon: rgba(0, 0, 0, 0.54);
  --dx-color-spin-icon: rgba(0, 0, 0, 0.54);
  --dx-color-link: #ff5722;
  --dx-color-shadow: black;
  --dx-color-separator: #e0e0e0;
}

/**
* $name 11. Button height
* $type text
*/
/**
* $name 12. Icon size
* $type text
*/
/**
* $name 13. Icon margin
* $type text
*/
/**
* $name 120. Border radius
* $type text
*/
:root {
  --dx-component-height: 32px;
  --dx-font-size: 13px;
  --dx-border-width: 1px;
  --dx-border-radius: 2px;
  --dx-font-size-icon: 18px;
}

:root {
  --dx-button-padding-inline: 12px;
}

/**
* $name 10. Text color
* $type color
*/
/**
* $name 20. Selected state text color
* $type color
*/
/**
* $name 15. Background color
* $type color
*/
/**
* $name 30. Hover state background color
* $type color
*/
/**
* $name 40. Focused state background color
* $type color
*/
/**
* $name 50. Active state background color
* $type color
*/
/**
* $name 25. Selected state background color
* $type color
*/
/**
* $name 10. Text color
* $type color
*/
/**
* $name 20. Selected state text color
* $type color
*/
/**
* $name 15. Background color
* $type color
*/
/**
* $name 30. Hover state background color
* $type color
*/
/**
* $name 40. Focused state background color
* $type color
*/
/**
* $name 50. Active state background color
* $type color
*/
/**
* $name 25. Selected state background color
* $type color
*/
/**
* $name 10. Text color
* $type color
*/
/**
* $name 20. Selected state text color
* $type color
*/
/**
* $name 15. Background color
* $type color
*/
/**
* $name 30. Hover state background color
* $type color
*/
/**
* $name 40. Focused state background color
* $type color
*/
/**
* $name 50. Active state background color
* $type color
*/
/**
* $name 25. Selected state background color
* $type color
*/
/**
* $name 10. Text color
* $type color
*/
/**
* $name 20. Selected state text color
* $type color
*/
/**
* $name 15. Background color
* $type color
*/
/**
* $name 30. Hover state background color
* $type color
*/
/**
* $name 40. Focused state background color
* $type color
*/
/**
* $name 50. Active state background color
* $type color
*/
/**
* $name 25. Selected state background color
* $type color
*/
.dx-icon-plus,
.dx-icon-overflow,
.dx-icon-add,
.dx-icon-airplane,
.dx-icon-arrowleft,
.dx-icon-arrowdown,
.dx-icon-arrowright,
.dx-icon-arrowup,
.dx-icon-bookmark,
.dx-icon-box,
.dx-icon-car,
.dx-icon-card,
.dx-icon-cart,
.dx-icon-chart,
.dx-icon-clock,
.dx-icon-close,
.dx-icon-comment,
.dx-icon-dataarea,
.dx-icon-datapie,
.dx-icon-datatrending,
.dx-icon-datausage,
.dx-icon-doc,
.dx-icon-download,
.dx-icon-edit,
.dx-icon-email,
.dx-icon-errorcircle,
.dx-icon-event,
.dx-icon-eventall,
.dx-icon-favorites,
.dx-icon-find,
.dx-icon-folder,
.dx-icon-food,
.dx-icon-gift,
.dx-icon-globe,
.dx-icon-group,
.dx-icon-help,
.dx-icon-home,
.dx-icon-image,
.dx-icon-info,
.dx-icon-key,
.dx-icon-like,
.dx-icon-map,
.dx-icon-menu,
.dx-icon-money,
.dx-icon-music,
.dx-icon-percent,
.dx-icon-photo,
.dx-icon-preferences,
.dx-icon-product,
.dx-icon-refresh,
.dx-icon-remove,
.dx-icon-runner,
.dx-icon-tags,
.dx-icon-tel,
.dx-icon-tips,
.dx-icon-todo,
.dx-icon-toolbox,
.dx-icon-user,
.dx-icon-save,
.dx-icon-clear,
.dx-icon-search,
.dx-icon-bold,
.dx-icon-italic,
.dx-icon-underline,
.dx-icon-strike,
.dx-icon-indent,
.dx-icon-fontsize,
.dx-icon-growfont,
.dx-icon-shrinkfont,
.dx-icon-color,
.dx-icon-background,
.dx-icon-superscript,
.dx-icon-subscript,
.dx-icon-header,
.dx-icon-blockquote,
.dx-icon-formula,
.dx-icon-codeblock,
.dx-icon-orderedlist,
.dx-icon-bulletlist,
.dx-icon-increaseindent,
.dx-icon-decreaseindent,
.dx-icon-alignleft,
.dx-icon-alignright,
.dx-icon-aligncenter,
.dx-icon-alignjustify,
.dx-icon-link,
.dx-icon-video,
.dx-icon-mention,
.dx-icon-variable,
.dx-icon-clearformat,
.dx-icon-undo,
.dx-icon-redo,
.dx-icon-checklist,
.dx-icon-verticalaligntop,
.dx-icon-verticalaligncenter,
.dx-icon-verticalalignbottom,
.dx-icon-rowproperties,
.dx-icon-columnproperties,
.dx-icon-cellproperties,
.dx-icon-tableproperties,
.dx-icon-splitcells,
.dx-icon-mergecells,
.dx-icon-removerow,
.dx-icon-removecolumn,
.dx-icon-addrowabove,
.dx-icon-addrowbelow,
.dx-icon-addcolumnleft,
.dx-icon-addcolumnright,
.dx-icon-addtable,
.dx-icon-removetable,
.dx-icon-bell,
.dx-icon-to,
.dx-icon-taskcomplete,
.dx-icon-taskrejected,
.dx-icon-taskinprogress,
.dx-icon-taskstop,
.dx-icon-taskhelpneeded,
.dx-icon-clearcircle,
.dx-icon-accountbox,
.dx-icon-ordersbox,
.dx-icon-arrowback,
.dx-icon-pinmap,
.dx-icon-handlevertical,
.dx-icon-handlehorizontal,
.dx-icon-triangleup,
.dx-icon-triangledown,
.dx-icon-triangleright,
.dx-icon-triangleleft {
  background-position: 0 0;
  background-repeat: no-repeat;
}

.dx-icon {
  background-position: 50% 50%;
}

.dx-svg-icon svg {
  pointer-events: none;
}

.dx-rtl .dx-icon-spinnext::before {
  content: "\f04f";
}
.dx-rtl .dx-icon-spinprev::before {
  content: "\f04e";
}
.dx-rtl .dx-icon-chevronnext::before {
  content: "\f012";
}
.dx-rtl .dx-icon-chevronprev::before,
.dx-rtl .dx-icon-back::before {
  content: "\f010";
}
.dx-rtl .dx-icon-undo::before {
  content: "\f093";
}
.dx-rtl .dx-icon-redo::before {
  content: "\f04c";
}
.dx-rtl .dx-icon-hidepanel::before {
  content: "\f11d";
}
.dx-rtl .dx-icon-showpanel::before {
  content: "\f11c";
}

:root {
  --dx-popup-toolbar-item-padding-inline: 8px;
}

/**
* $name 10. Text color
* $type color
*/
/**
* $name 20. Placeholder text color
* $type color
*/
/**
* $name 30. Background color
* $type color
*/
/**
* $name 40. Focused background color
* $type color
*/
/**
* $name 50. Border color
* $type color
*/
/**
* $name 60. Hovered border color
* $type color
*/
/**
* $name 70. Focused border color
* $type color
*/
/**
* $name 40. Hovered background color
* $type color
*/
:root {
  --dx-texteditor-color-text: rgba(0, 0, 0, 0.87);
  --dx-texteditor-color-label: rgba(0, 0, 0, 0.6);
}

:root {
  --dx-font-size-heading-1: 64px;
  --dx-font-size-heading-2: 42px;
  --dx-font-size-heading-3: 20px;
  --dx-font-size-heading-4: 18px;
  --dx-font-size-heading-5: 16px;
  --dx-font-size-heading-6: 14px;
  --dx-font-size-xl: 24px;
  --dx-font-size-lg: 20px;
  --dx-font-size-md: 16px;
  --dx-font-size-sm: 14px;
  --dx-font-size-xs: 12px;
}

:root {
  --dx-toolbar-height: 38px;
}

/**
* $name 10. Group header text color
* $type color
*/
/**
* $name 20. Group border color
* $type color
*/
/**
* $name 20. Group header background color
* $type color
*/
/**
* $name 10. Item text color
* $type color
*/
/**
* $name 25. Expanded group header text color
* $type color
*/
/**
* $name 30. Hovered state item background color
* $type color
*/
/**
* $name 30. Selected state item background color
* $type color
*/
/**
* $name 40. Active state item background color
* $type color
*/
:root {
  --dx-list-item-padding-inline: 11px;
  --dx-list-item-padding-block: 10px;
}

:root {
  --side-panel-min-width: 48px;
  --content-padding: 16px;
  --header-toolbar-vertical-padding: 10px;
  --footer-height: 70px;
  --toolbar-vertical-padding: 16px;
  --toolbar-margin-bottom: var(--content-padding);
  --base-bg: #fff;
  --background-color: var(--base-bg);
  --base-bg-darken-5: #f2f2f2;
  --base-bg-darken-4: whitesmoke;
  --side-panel-background: var(--base-bg-darken-5);
  --card-background: var(--base-bg);
  --calendar-width: 292px;
  --border-color: #e0e0e0;
  --accent-color: #ff5722;
  --base-text-color: rgba(0, 0, 0, 0.87);
  --base-focus-color: #fff;
  --background-gray-color: rgba(0, 0, 0, 0.04);
  --texteditor-edit-color: rgba(0, 0, 0, 0.87);
  --texteditor-label-color: rgba(0, 0, 0, 0.6);
  --base-text-color-alpha: rgba(0, 0, 0, 0.609);
  --toolbar-height: 38px;
  --filled-texteditor-input-horizontal-padding: 8px;
  --list-padding-left: 11px;
  --list-padding-vertical: 10px;
  --footer-left-padding: calc((var(--side-panel-min-width) - 18px) / 2);
  --popup-toolbar-item-spacing: 8px;
  --drop-down-gnrc-button-height: 32px;
  --theme-padding: 12px;
  --tooltip-danger-button-color: rgba(244, 67, 54, 0.1);
  --tooltip-success-button-color: rgba(139, 195, 74, 0.1);
  --subtitle-text-color: var(--texteditor-label-color);
  --button-height-theme-difference: calc(
    (var(--theme-padding) - 10px) / 3
  );
  --button-height: calc(
    26px + var(--button-height-theme-difference)
  );
  --scheduler-toolbar-height: calc(
    var(--button-height) + 2 * var(--theme-padding)
  );
}